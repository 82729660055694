.ProjectsList {
  background: var(--dark-mode-bg-9);
  padding: 2rem 0;
}

.Container {
  max-width: 1170px;
  margin: 0 auto;
  font-family: "Google Sans", sans-serif;
}
