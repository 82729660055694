.Navbar {
  font-family: "Google Sans", sans-serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: white;
  transition: 0.2s;
}

.Navbar.active {
  box-shadow: 0 1px 2px 0 rgba(140, 147, 151, 0.247),
    0 2px 6px 2px rgba(147, 155, 161, 0.13);
}

.Container {
  max-width: 1170px;
  min-height: 70px;
  margin: 0 auto;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
  transition: 0.2s;
}

.NavbarLeft {
  float: left;
  box-sizing: border-box;
  transition: 0.2s;
}

.NavbarLogo {
  top: 0;
  padding: 0 0 0 0;
  display: flex;
  justify-content: flex-start;
  float: none;
  width: auto;
  font-size: 19px;
  line-height: 21px;
  align-items: center;
  transition: 0.2s;
}

.NavbarLogo:active,
.NavbarLogo:hover {
  border-bottom: none;
}

.NavigationItems {
  margin: 0;
  padding-left: 0;
  list-style: none;
  height: 100%;
  align-items: center;
  transition: 0.2s;
}

.NavigationItem {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: stretch;
  height: auto;
}

.NavigationItem a {
  box-sizing: border-box;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  color: var(--text-color);
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  text-decoration: none;
  /* background: #fff; */
  background: none;
  margin: 0;
  padding: 5px 12px 2px 12px;
  border-bottom: 3px solid white;
  top: 0;
  position: relative;
}

.NavigationItem a:hover {
  color: #5f6368;
}

.NavigationItem:nth-child(5) a {
  color: var(--google-green);
}

.NavigationItem:nth-child(5) a.selected::after,
.NavigationItem:nth-child(5) a:hover::after,
.NavigationItem:nth-child(5) a:active::after {
  content: "";
  border-radius: 3px;
  height: 3px;
  display: block;
  position: absolute;
  bottom: -3px;
  background: var(--google-med-green);
  z-index: 2;
  width: 72%;
}

a.selected::after,
.NavigationItem a:hover::after,
.NavigationItem a:active::after {
  content: "";
  border-radius: 3px;
  height: 3px;
  display: block;
  position: absolute;
  bottom: -3px;
  background: #185abc;
  z-index: 2;
  width: 60%;
  /* transform: translateY(0);
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1; */
}

.Popup {
  display: flex;
  align-items: center;
}

.Popup:hover {
  cursor: pointer;
}

@media (min-width: 1200px) {
  .Container {
    width: 1170px;
    min-height: 70px;
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .Container {
    max-width: 100vw;
    display: flex;
    justify-content: space-between;
    margin: 0;
  }
}

@media (min-width: 767px) {
  .NavbarLeft {
    align-items: center;
    display: flex;
  }
  .NavigationItems {
    display: flex;
  }
  .NavbarLogo {
    height: 38px;
    padding-left: 20px;
    margin-left: -20px;
  }
}

@media (max-width: 767px) {
  .Navbar {
    display: none !important;
  }
}
