.AboutAKGEC {
    margin: 0 auto;
    padding: 2rem 0 2rem 0;
    transition: 0.2s;
    background-color: #fff;
  }
.Container {
    max-width: 1170px;
    margin: 0 auto;
    transition: 0.2s;
  }
  .AboutAKGEC .AKGECHeader {
    font-size: 48px;
    font-family: "Google Sans", sans-serif;
    line-height: 1.2;
    padding: 1rem 0 3rem 0;
    font-weight: 400;
    transition: 0.2s;
  }
  
.AKGECHeader span{
    font-weight: bold;
}
.AboutAKGEC .AKGECContent{
    display: flex;
  justify-content: space-between;
  align-items: center;
}
.AboutAKGEC .AKGECContent img{
    width: 540px;
  transition: 0.2s;
}
.AboutAKGEC .AKGECContent .AKGECBody p{
    font-size: 20px;
  font-family: "Google Sans", sans-serif;
  line-height: 1.5;
  padding-bottom: 2rem;
  transition: 0.2s;
}
.AboutAKGEC .AKGECContent .AKGECBody{
    padding: 1rem 4rem;
  transition: 0.2s;
}
.AboutAKGEC .AKGECContent .AKGECBody a{
    padding: 12px 30px;
  text-decoration: none;
  color: white;
  background: var(--google-blue);
  border-radius: 2px;
  display: inline-block;
  transition: 0.2s;
  font-size: 14px;
  font-weight: 500;
  transform: translateY(-1px);
}
.AboutAKGEC .AKGECContent .AKGECBody a:hover{
    background: var(--google-dark-blue);
  box-shadow: -4px 4px 8px var(--button-shadow-color);
  border-radius: 2px;
}
.AboutAKGEC .AKGECContent .AKGECBody a:active{
    transform: translateY(0px);
}
@media (max-width: 1199px) {
    .Container {
      padding: 0 10px;
    }
    .AboutAKGEC .AKGECContent .AKGECBody {
      padding: 1rem 1.3rem;
    }
    .AboutAKGEC .AKGECContent img {
      width: 480px;
    }
  }
  
  @media (max-width: 991px) {
    .AboutAKGEC .AKGECContent img {
      width: 380px;
    }
  }
  
  @media (max-width: 785px) {
    .AboutAKGEC .AKGECContent img {
      width: 280px;
    }
    .AboutAKGEC .AKGECContent .AKGECBody p {
      font-size: 16px;
      padding-bottom: 1.5rem;
    }
    .AboutAKGEC .AKGECHeader {
      text-align: center;
    }
  }
  
  @media (max-width: 599px) {
    .AboutAKGEC .AKGECContent img {
      display: none;
    }
    .AboutAKGEC .AKGECHeader {
      font-size: 36px;
      padding-bottom: 1rem;
    }
    .AboutAKGEC .AKGECContent .AKGECBody p {
      display: block;
      text-align: center;
      font-size: 18px;
      padding-bottom: 2rem;
    }
    .AboutAKGEC {
      text-align: center;
      padding: 2rem;
    }
  }
  
  @media (max-width: 399px) {
    .AboutAKGEC .AKGECHeader {
      font-size: 30px;
      padding-bottom: 1rem;
    }
    .AboutAKGEC .AKGECContent .AKGECBody {
      padding: 0.2rem;
    }
    .AboutAKGEC .AKGECContent .AKGECBody p {
      display: block;
      text-align: center;
      font-size: 15px;
      padding-bottom: 2rem;
    }
    .AboutAKGEC {
      text-align: center;
      padding: 1rem 0.8rem 2rem 0.8rem;
    }
  }
  