.btn {
  position: absolute;
  bottom: 2%;
  right: 2rem;
}
@media screen and (max-width: 1050px) {
  .btn {
    bottom: 1.5%;
    right: 2rem;
  }
}
@media screen and (max-width: 1033px) {
  .btn {
    bottom: 1.5%;
    right: 1.5rem;
  }
}
@media screen and (max-width: 688px) {
  .btn {
    bottom: 1.05%;
    right: 0rem;
  }
}
@media screen and (max-width: 550px) {
  .btn {
    bottom: 1.05%;
    right: 0.1rem;
  }
}
@media screen and (max-width: 421px) {
  .btn {
    bottom: 1.05%;
    right: 0.1rem;
  }
}
@media screen and (max-width: 347px) {
  .btn {
    
    bottom: 1.1%;
    right: 0rem;
  }
}
@media screen and (max-width: 277px) {
  .btn {
  
    bottom: 1.05%;
    right: 0rem;
  }
}
