.ThemeButton {
  display: inline-block;
  padding: 0.6rem;
  border: none;
  font-size: 1.2rem;
  line-height: 0.7;
  border-radius: 50%;
  background-color: var(--google-gray-shade-8);
  color: var(--google-gray-shade-1);
  margin-left: 1rem;
  transition: 0.2s all;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.ThemeButton:hover {
  cursor: pointer;
  transform: translateY(-2px);
}
.ThemeButton:focus,
.ThemeButton:active {
  outline: none;
}
@media (max-width: 450px) {
  .ThemeButton {
    padding: 0.4rem;
    font-size: 1rem;
  }
}
