@font-face {
  font-family: ProductSans-Bold;
  src: url(../../assets/fonts/ProductSans-Bold.ttf);
}
@font-face {
  font-family: Product Sans;
  src: url(../../assets/fonts/ProductSans-Regular.ttf);
}

.Logo {
  /* width: 21.8rem; */
  /* height: 42.36px; */
  height: 42.36px;
  transition: 0.2s;
  margin-top: 2vh;
  font-family: ProductSans-Bold;
  font-weight: 700;
}


@media (max-width: 490px) {
  .Logo {
    height: 33.36px;
  }
}
@media (max-width: 390px) {
  .Logo {
    height: 30.36px;
  }
}

@media (max-width: 330px) {
  .Logo {
    height: 25.36px;
  }
}

@media (max-width: 310px) {
  .Logo {
    height: 21.76px;
  }
}