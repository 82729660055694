.Team {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.btnDiv {
  display: flex;
  justify-content: center;
}

.alumniBtn {
  padding: 12px 30px;
  text-decoration: none;
  color: white;
  background: var(--google-blue);
  border: none;
  border-radius: 2px;
  display: inline-block;
  transition: 0.2s;
  font-size: 14px;
  font-weight: 500;
  transform: translateY(-1px);
  margin-bottom: 2rem;
}

.alumniBtn:hover {
  background: var(--google-dark-blue);
  /* box-shadow: -4px 4px 8px var(--button-shadow-color); */
  border-radius: 2px;
  color: white;
  text-decoration: none;
}

.alumniBtn:active {
  transform: translateY(0px);
}

@media screen and (max-width: 425px) {
  .alumniBtn {
    font-size: 10px;
  }
}