.Footer {
  padding-top: 52px;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  background-color: var(--dark-mode-bg-5);
  transition: 0.2s;
}

.Container {
  max-width: 1100px;
  margin: 0 auto;
}

.footerTop {
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  transition: 0.2s;
}

.footerTop .logo {
  padding: 0 15px;
  /* border-bottom: 1px solid #fff; */
  transition: 0.2s;
  align-self: center;
}

.footerTop .logo a > img {
  height: 3.5rem;
}

.quickLinks,
.socials {
  color: var(--dark-mode-text-color-2);
  font-family: "Google Sans", sans-serif;
  font-size: 14px;
  transition: 0.2s;
  margin-top: 1.5rem;
}

.quickLinks .linkHeader,
.socials .socialsHeader {
  color: var(--dark-mode-text-color-2);
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  margin: 5px 0 45px 0;
  width: 100%;
  transition: 0.2s;
}

.linkList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 20px;
  list-style: none;
  transition: 0.2s;
}

.quickLinks .linkList li {
  margin-left: 18px;
  transition: 0.2s;
  margin-bottom: 15px;
}

.quickLinks .linkList li a {
  text-decoration: none;
  color: var(--dark-mode-text-color-soft-1);
  width: 100%;
  padding: 6px 12px;
  line-height: 1.3;
  transition: 0.2s;
  box-shadow: inset 0 0 0 0 var(--dark-mode-text-color-2);
  transition: ease-out 0.3s;
  outline: none;
}

.quickLinks .linkList li a:hover {
  box-shadow: inset 145px 0 0 0 var(--dark-mode-text-color-2);
  color: var(--dark-mode-bg-2);
}

.socials {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socials .socialLinks {
  list-style: none;
  display: flex;
  width: 40%;
  justify-content: space-between;
  transition: 0.2s;
}

.socials .socialLinks li {
  margin: 0 6px 6px 0;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 25px;
}

.socials .socialLinks li a {
  text-decoration: none;
  display: inline-block;
  background: var(--dark-mode-bg-1);
  padding: 1rem;
  border-radius: 50%;
  line-height: 0.6;
  transition: all 0.2s;
}

.socials .socialLinks svg {
  font-size: 35px;
  color: var(--dark-mode-text-color-soft-1);
  transition: 0.2s;
}
.socials .socialLinks li a:hover {
  background: var(--dark-mode-text-color-2);
}
.socials .socialLinks li a:hover svg {
  color: var(--dark-mode-bg-2);
}

.footerGap {
  margin-top: 45px;
  border: 1px solid var(--dark-mode-bg-4);
  overflow: auto;
  width: 500%;
  margin-left: -200%;
  transition: 0.2s;
}

.footerBottom {
  padding: 20px 0;
}

.footerBottomContainer {
  padding: 0 15px;
  margin: 0 auto;
}

.copyright {
  font-family: "Google Sans", sans-serif;
  font-size: 20px;
  text-align: center;
  color: var(--dark-mode-text-color-soft-1);
}

@media (max-width: 1050px) {
  .footerTop .logo a > img {
    height: 4rem;
  }
  .Footer {
    padding-top: 2rem;
    text-align: center;
  }
  .footerTop .logo {
    padding-bottom: 10px;
  }
  .footerTop {
    grid-template-columns: 1fr;
  }
  .quickLinks {
    padding: 10px 20px;
  }
  .linkList {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  .quickLinks .linkList li {
    list-style: none;
  }
  .socials {
    padding: 10px 20px;
  }
  .socials .socialLinks {
    width: 70%;
  }
  .socials .socialLinks li a {
    padding: 0.8rem;
  }
  .footerGap {
    margin: 2rem 0 1rem 0;
    border: 1px solid var(--dark-mode-bg-4);
    overflow: auto;
    width: 500%;
    margin-left: -200%;
    transition: 0.2s;
  }
  .footerBottom {
    padding: 10px 0;
  }
  .copyright {
    font-size: 20px;
  }
}

@media (max-width: 550px) {
  .footerTop .logo a > img {
    height: 3rem;
  }
  .footerTop {
    padding: 0 10px;
  }
  .quickLinks .linkHeader,
  .socials .socialsHeader {
    text-align: center;
    font-size: 24px;
    margin: 5px 0 40px 0;
    width: 100%;
  }
  .socials .socialLinks li a {
    padding: 0.5rem;
    line-height: 0.6;
  }
  .socials .socialLinks svg {
    font-size: 20px;
  }
  .footerGap {
    margin: 0.6rem 0 1rem 0;
  }
  .linkList {
    font-size: 14px;
  }
  .quickLinks .linkList li a {
    padding: 3px 6px;
  }
  .copyright {
    font-size: 18px;
  }
}

@media (max-width: 420px) {
  .footerTop,
  .footerTop .logo,
  .quickLinks,
  .socials {
    padding-left: 0;
    padding-right: 0;
  }
  .footerTop .logo a > img {
    height: 2rem;
  }
  .quickLinks .linkHeader,
  .socials .socialsHeader {
    text-align: center;
    font-size: 22px;
    margin: 5px 0 40px 0;
    width: 100%;
  }
  .socialLinks {
    padding-left: 0;
  }
  .socials .socialLinks {
    width: 75%;
  }
}
