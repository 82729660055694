.TeamList {
  max-width: 1170px;
  margin: 0 auto;
  padding: 2rem 0;
  font-family: "Google Sans", sans-serif;
}

.listContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.teamListHeader {
  font-weight: 400;
  padding: 2rem 2rem;
  transition: 0.2s;
}

.alumniBatchHeader {
  font-weight: 400;
  padding: 0 2rem;
  font-size: 1.5rem;
}

.section {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 2rem;
}

.section:last-child {
  border-bottom: none;
}

@media (max-width: 704px) {
  .teamListHeader {
    text-align: center;
    font-size: 30px;
    padding: 2rem 0 1.5rem 0;
  }
  .alumniBatchHeader {
    text-align: center;
    font-size: 22px;
    padding: 1rem 0;
  }
}

@media (max-width: 350px) {
  .teamListHeader {
    font-size: 24px;
  }
  .alumniBatchHeader {
    font-size: 18px;
  }
}
