.container{
    height: 89.2vh;
    width: 99.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrapper{
    width: 400px;
    height: 300px;
    /* margin-top: 20px; */
    /* background-color: aqua; */
}
.cont{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.cont1{
    font-size: 100px;
    color: rgb(61, 53, 53);
    letter-spacing: 5px;
    margin-right: 30px;
    border-right: solid  rgb(61, 53, 53) ;
    padding-right: 20px;
    /* margin: auto; */
}

.page{
    color:rgb(61, 53, 53);
    font-size: 35px;
}
.para{
    margin-top: 10px;
    font-size: 18px;
    color: rgb(61, 53, 53);
}
.btn{
    
    margin-left: 30%;
}
.btn1{
    font-size: 15px;
    padding: 10px 35px;
    background-color: var(--google-blue);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 3px;
}
.btn1:hover{
    background-color: var(--google-dark-blue);
}
@media screen and (max-width: 768px) {
    .container{
        height: 100vh;
        width: 100%;
        /* background-color: aqua; */
    }
    .wrapper{
        /* background-color: aqua; */
        height: 30%;
        width: 80%;
        
    }
    
    .cont1{
        font-size: 80px;
    }
    .para{
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        /* margin: auto; */
    }
    .btn{
        margin-left: 20%;
    }
}