.container {
  background: url("../../../assets/background/background15.png") no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: 70px;
}

.ShowcaseHome {
  max-width: 1170px;
  margin: 0 auto;
  height: 578px;
  display: flex;
  padding: 1.5rem 0px;
  align-items: center;
  transition: 0.2s;
}

.ShowcaseHome h1 {
  font-size: 54px;
  font-family: "Google Sans", sans-serif;
  font-weight: normal;
  padding: 3rem 0 2rem 0;
  margin: 0;
  color: #202124;
  line-height: 1.2;
  transition: 0.2s;
}

.ShowcaseHome h1 span {
  font-weight: bold;
}

.ShowcaseHome p {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: var(--text-color);
  line-height: 1.5;
  text-align: justify;
  padding-right: 2.5rem;
  margin-bottom: 2rem;
  transition: 0.2s;
}

.ShowcaseHome img {
  width: 550px;
  transition: 0.2s;
  /* background: white; */
}

@media (max-width: 1300px) {
  .ShowcaseBody {
    padding-left: 20px;
  }
}

@media (max-width: 1200px) {
  .ShowcaseHome {
    height: 600px;
    padding-left: 10px;
  }
  .ShowcaseHome h1 {
    font-size: 43px;
  }
  .ShowcaseHome img {
    width: 510px;
  }
}

@media (max-width: 991px) {
  .ShowcaseHome {
    height: 678px;
  }
  .ShowcaseBody {
    padding-left: 0px;
  }
  .ShowcaseHome h1 {
    font-size: 34px;
  }
  .ShowcaseHome p {
    padding-right: 1rem;
  }
  .ShowcaseHome img {
    width: 425px;
  }
}

@media (max-width: 785px) {
  .ShowcaseHome {
    height: 450px;
    text-align: center;
    padding-left: 0;
  }
  .ShowcaseHome h1 {
    font-size: 32px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .ShowcaseHome p {
    padding: 0 2.5rem;
    font-size: 15px;
    text-align: center;
  }
  .ShowcaseHome img {
    display: none;
  }
  .ShowcaseBody {
    padding-left: 0;
  }
}

@media (max-width: 499px) {
  .ShowcaseHome {
    height: 450px;
    text-align: center;
  }
  .ShowcaseHome h1 {
    font-size: 26px;
    padding: 0.5rem 0.25rem 1rem 0.25rem;
  }
  .ShowcaseHome p {
    padding: 0 0.2rem;
    font-size: 14px;
  }
}
