.container {
  background: #fff;
}

.AboutHome {
  max-width: 1170px;
  margin: 0 auto;
  padding: 2.5rem 15px;
  transition: 0.2s;
}

.AboutHome .AboutHomeImg {
  height: 200px;
  display: block;
  margin: 1rem auto 4rem auto;
  transition: 0.2s;
}

.AboutHome h2,
.AboutHome p {
  text-align: center;
  transition: 0.2s;
}

.AboutHome h2 {
  font-size: 52px;
  color: #202124;
  font-family: "Google Sans", "Noto Sans", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  padding-top: 2rem;
  transition: 0.2s;
}

.AboutHome p {
  padding: 3rem 2rem;
  font-size: 20px;
  line-height: 1.5;
  text-justify: inter-word;
  color: var(--text-color);
  transition: 0.2s;
}

.Domains h2 {
  text-align: center;
  font-size: 56px;
  font-weight: 400;
  color: #202124;
  font-family: "Google Sans", sans-serif;
  padding: 1rem;
  position: relative;
  transition: 0.2s;
  background-color: #f1f3f4;
}

.Domains .card {
  display: flex;
  padding: 3.5rem 1rem 3rem 1rem;
  transition: 0.2s;
  margin-top: -8px;
}

.Domains .card > div {
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blue {
  color: var(--google-dark-blue);
}
.red {
  color: var(--google-red);
}
.green {
  color: var(--google-dark-green);
}
.yellow {
  color: var(--google-dark-yellow);
}
.violet {
  color: var(--google-uiux-team-color);
}

.Domains .card:nth-child(even) {
  background: #f1f3f4;
}

.Domains .card:nth-child(odd) .cardBody {
  order: 2;
}

.Domains .card .cardBody {
  flex: 1;
}

.Domains .card .cardBody .cardHeader {
  font-family: "Google Sans", "Noto Sans", sans-serif;
  font-weight: 600;
  font-size: 45px;
  line-height: 1.2;
  padding: 2rem 0 1rem 0;
  transition: 0.2s;
}

.Domains .card .cardBody .cardText {
  font-size: 18px;
  font-weight: 400;
  font-family: "Google Sans", Arial, Helvetica, sans-serif;
  color: var(--text-color);
  line-height: 1.5;
  padding: 1rem 0;
  text-align: justify;
  transition: 0.2s;
}

.Domains .DomainsImg {
  display: block;
  height: 270px;
  flex: 1;
  transition: 0.2s;
}

@media (max-width: 992px) {
  .Domains .card {
    /* padding-top: 2rem; */
    /* margin-top: 3rem; */
  }
  .Domains .DomainsImg {
    width: 300px;
    padding: 1rem;
  }
  .Domains .card .cardBody .cardHeader {
    font-size: 40px;
  }
  .Domains .card .cardBody .cardText {
    font-size: 17px;
  }
}

@media (max-width: 785px) {
  .AboutHome h2 {
    font-size: 32px;
  }
  .AboutHome p {
    font-size: 16px;
  }
  .AboutHome .AboutHomeImg {
    height: 130px;
  }
  .Domains h2 {
    font-size: 36px;
    padding: 1rem 1rem 3rem 1rem;
  }
  .Domains .card {
    padding: 0.7rem;
    margin: 2rem 0;
  }
  .Domains .DomainsImg {
    width: 250px;
    height: 200px;
    padding: 0 0.7rem 0 0.7rem;
  }
  .Domains .card .cardBody .cardHeader {
    font-size: 24px;
  }
  .Domains .card .cardBody .cardText {
    font-size: 16px;
    line-height: 1.4;
  }
}

@media (max-width: 499px) {
  .AboutHome {
    padding-bottom: 1rem;
  }
  .AboutHome h2 {
    font-size: 22px;
  }
  .AboutHome p {
    font-size: 14px;
  }
  .AboutHome .AboutHomeImg {
    height: 68px;
  }
  .Domains h2 {
    font-size: 28px;
    padding: 1rem 1rem 1rem 1rem;
  }
  .Domains .card {
    padding: 1rem;
    margin: 1.6rem 0;
    text-align: center;
  }
  .Domains .DomainsImg {
    display: none;
  }
  .Domains .card .cardBody .cardHeader {
    font-size: 24px;
    padding: 1.2rem;
  }
  .Domains .card .cardBody .cardText {
    font-size: 14px;
    line-height: 1.5;
  }
}
