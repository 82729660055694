.Button {
  padding: 12px 30px;
  text-decoration: none;
  color: #fff;
  display: inline-block;
  margin: 2rem 0;
  border-radius: 4px;
  transition: 0.2s;
  font-size: 14px;
  font-weight: 500;
  transform: translateY(-1px);
  background: var(--google-blue);
}

.Button i,
.Button span {
  vertical-align: middle;
}

.Button:hover {
  color: white;
  background-color: var(--google-dark-blue);
  box-shadow: -4px 4px 16px var(--button-shadow-color);
  text-decoration: none;
}

.Button:active {
  transform: translate(0px);
  box-shadow: -2px 2px 4px #b3b3b3;
}

/* @media (max-width: 785px) {
  .Button {
    padding: 6px 15px;
    margin: 1.5rem 0;
    font-size: 12px;
  }
  .Button i {
    font-size: 24px;
  }
}

@media (max-width: 499px) {
  .Button {
    padding: 6px 15px;
    margin: 1rem 0;
    font-size: 12px;
  }
  .Button i {
    font-size: 20px;
  }
} */

/* Google light color : #9FC2FF */
/* Google dark color : #185abc */
/* Google med color : #4285f4 */
