@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Montserrat:wght@700&family=Open+Sans&family=PT+Serif&family=Poppins&family=Roboto&family=Squada+One&display=swap');

.successSection{
  width:100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
  font-family: "poppins";
}

.successSection::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/background/TYL.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  filter: blur(10px) brightness(0.6)  saturate(0.8);
  z-index: -1;
}
.successContainer {
  display: flex;
  color: white;
  /* border:2px solid grey; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  width:100%;
  padding: 40px;
  text-align: center;
  overflow: hidden;
  font-family: "poppins";
}

.successHeader {
  font-size: 2rem;
  margin-bottom: 2rem;
  
}

.successSection .successFooter{
  margin: 10vw 2px;
  font-size: 0.9rem;
  color: white;
  padding: 0 10px;
  display: flex;
  flex-direction:column ;
  justify-content: center;
  align-items: center;
  text-align: center;

}
.successSection .footerText{
  color: #ffffff;
  font-size: 20px;
}

.successMessage {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 2px #b9c6cd8f;
  /* color: #111110; */
}

.successButton {
  background-color: #48ae85;
  border: none;
  color: white;
  padding: 12px 28px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
  z-index: 2;
}

.successButton:hover {
  background-color: #11452b;
}
@media screen and (max-width: 356px) {
  .successContainer .successHeader{
   font-size: 1.7rem;
   margin-bottom: 2rem;
   margin-top: 2.5rem;
  }
  .successContainer .footerText{
   font-size: 16px;
   margin-bottom: 4rem;
  }
 
  .successButton{
    padding: 9px 24px;
   }
 
  }