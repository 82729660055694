.TeamMember {
  width: 200px;
  height: 380px;
  margin: 0 2rem;
  font-family: "Google Sans", sans-serif;
}

.card {
  position: relative;
  width: 200px;
  margin: 20px 0;
  background: #121212;
  box-shadow: 15px 15px 17px #0b0b0b, -15px -15px 17px #191919;
  overflow: hidden;
  text-align: center;
  height: 340px;
  border-radius: 5px;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.card::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: calc(-100% + 4px);
  width: 100%;
  height: 100%;
  /* background: var(--dark-mode-text-color-2); */
  /* background-image: linear-gradient(-20deg, #f794a4 0%, #fdd6bd 100%); */
  background-image: linear-gradient(to top, #b224ef 0%, #7579ff 100%);
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.card:hover::before {
  bottom: 0;
}

.card div {
  position: relative;
  z-index: 2;
}

.cardImg {
  width: 200px;
  padding: 1.6rem;
  transition: 0.3s;
}

.cardImg img {
  width: 100%;
  /* border: 3px solid var(--dark-mode-text-color-2); */
  border: 3px solid #7579ff;
  border-radius: 50%;
  display: inline-block;
  transition: 0.3s;
}

.card:hover .cardImg {
  padding: 0;
}

.card:hover .cardImg img {
  border-radius: 0;
  border: none;
}

.cardBody {
  padding: 0.5rem;
}

.cardName {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;
  transition: 0.3s;
  color: var(--dark-mode-text-color-soft-1);
}

.cardDesignation {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1.2rem;
  color: var(--dark-mode-text-color-soft-2);
  transition: 0.3s;
  line-height: 1.3;
}

.card:hover .cardDesignation,
.card:hover .cardName {
  /* color: var(--dark-mode-bg-1); */
  color: #fff;
}

.card:hover .cardDesignation {
  font-weight: 500;
  /* color: var(--dark-mode-text-color-soft-2); */
  color: rgba(255, 255, 255, 0.527);
}

.linkList {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkIcon{
  text-decoration: none;
  cursor: pointer;
  color: var(--text-color);
  padding: 8px;
  transition: 0.3s;
  font-size: 17px;
  height: 34px;
  width: 34px;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card:hover .linkIcon {
  /* color: var(--dark-mode-bg-1); */
  color: #fff;
  border-radius: 50%;
  align-items: center;
}

.linkIcon:hover {
  /* background: var(--dark-mode-bg-8); */
  background: #fff;
  border-radius: 50%;
}

.linkIcon:hover svg {
  /* color: var(--dark-mode-text-color-2); */
  color: #b224ef;
}

@media (max-width: 350px) {
  .TeamMember {
    height: 380px;
    width: auto;
  }
  .card {
    width: 200px;
    height: 330px;
  }
  .cardImg {
    width: 200px;
    padding: 1.2rem;
  }
  .cardName {
    font-size: 20px;
  }
  .cardDesignation {
    font-size: 16px;
    color: var(--text-color);
  }
}
