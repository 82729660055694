.SideDrawer {
  background-color: #fff;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  z-index: 1000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.logo {
  flex: 3;
}

.themeMode {
  margin-right: 1rem;
}

.SideDrawer.active {
  box-shadow: 0 1px 2px 0 rgba(140, 147, 151, 0.247),
    0 2px 6px 2px rgba(147, 155, 161, 0.13);
}

.SideDrawer .menuBars {
  margin: 0 1rem;
  font-size: 2rem;
  background: white;
  color: var(--text-color);
}

.SideDrawer .menuBars:hover,
.SideDrawer .menuBars:active,
.close:hover,
.close:active {
  color: #202124;
  transform: scale(1.08);
}

.navMenu {
  background-color: #fff;
  width: 250px;
  height: 100vh;
  z-index: 1000;
  box-shadow: 0 2px 8px rgb(185, 185, 185);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.navMenu.navActive {
  left: 0;
  transition: 350ms;
}

.navText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  height: 60px;
  font-family: "Google Sans", "Noto Sans", sans-serif;
  font-weight: 900;
  border-bottom: 1px solid var(--border-color);
}

/* .navText:last-child {
  justify-content: center;
  margin-left: -3rem;
  border-bottom: none;
  margin-top: 2rem;
} */

.navText:hover {
  box-shadow: 5px 5px 20px #eee;
}

.navText a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 14px;
  width: 100%;
  padding: 21px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 0px 30px 30px 0;
  transition: 0.3s;
  border-left: 6px solid #fff;
}

.navText a svg {
  font-size: 20px;
}
.navMenuItems {
  width: 100%;
}

/* Selected class */
.navText .selected {
  border-left: 6px solid var(--google-blue);
  transition: 0.2s;
}
.navText .selected svg,
.navText .selected span {
  color: var(--google-blue);
  font-weight: 900;
  transition: 0.2s;
}

.navText:last-child .selected {
  border-left: 6px solid var(--google-med-green);
  transition: 0.2s;
}

.navText:last-child .menuBars {
  color: var(--google-med-green);
}

.navText:last-child .selected svg,
.navText:last-child .selected span {
  color: var(--google-med-green);
  font-weight: 900;
  transition: 0.2s;
}

.navText a:hover {
  border-left: 6px solid var(--google-blue);
}

/* .navText:last-child a:hover {
  border-left: 6px solid var(--google-med-green);
} */

.SideDrawerToggle {
  background-color: #fff;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 2rem;
  padding: 0 20px;
}
.SideDrawerToggle svg {
  color: var(--text-color);
}

.menuBars span {
  margin-left: 16px;
}

@media (min-width: 768px) {
  .Container {
    display: none;
  }
}
