.EventsList {
  max-width: 1170px;
  margin: 0 auto;
  font-family: "Google Sans", sans-serif;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0;
  justify-content: center;
}
