.card {
  background: var(--dark-mode-neu-bg);
  box-shadow: var(--dark-mode-neu-box-shadow);
  width: 320px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  margin-right: 10px;
  transition: 0.2s;
  /* z-index: 1; */
}

.card:not(:last-child) {
  margin-right: 20px;
}

.card .cardImage {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 15px 15px 0 0;
  overflow: hidden;
  display: inline-block;
  object-fit: cover;
}

.cardHeader {
  font-size: 24px;
  color: var(--dark-mode-text-color-3);
  font-weight: 500;
  text-align: left;
  padding: 0 0 1rem 0;
  line-height: 1.2;
  transition: 0.2s;
  height: 60px;
}

.cardHeader:hover,
.cardHeader:link {
  color: var(--dark-mode-text-color-1);
}
.cardBody {
  padding: 1rem 1.4rem 1.2rem 1.4rem;
  text-align: left;
  color: var(--dark-mode-text-color-soft-1);
  transition: 0.2s;
}

.cardBody p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding-bottom: 1rem;
  text-align: left;
  line-height: 1.4;
  text-align: justify;
  transition: 0.2s;
  height:125px;
}

.cardBody .btn {
  display: inline-block;
  padding: 0 8px;
  line-height: 36px;
  cursor: pointer;
  height: 36px;
  color: var(--dark-mode-text-color-soft-1);
  border-radius: 4px;
  background: linear-gradient(45deg, #363c41, #2d3237);
  box-shadow: 5px 3px 10px #25292d, -5px -3px 10px #40474d;
  font-size: 15px;
  vertical-align: middle;
  margin: 0 -4px;
  font-weight: 500;
  transform: translateY(0);
  border-radius: 4px;
  transition: all 0.2s;
}

.cardBody a {
  text-decoration: none;
}

.cardBody .btn:hover {
  background: linear-gradient(45deg, #33393d, #262a2e);
  box-shadow: 5px 3px 10px #282c30, -5px -3px 10px #424a50;
  border-radius: 4px;
  color: var(--dark-mode-text-color-1);
  transform: translateY(-1px);
}

.cardbody .btn:active {
  border-radius: 4px;
  background: linear-gradient(45deg, #2d3237, #363c41);
  box-shadow: 7px 7px 12px #25292d, -7px -7px 12px #40474d;
}

@media (max-width: 991px) {
  .card:not(:last-child) {
    margin-right: 0;
  }
  .card {
    margin: 0 10px;
  }
  .card .cardImage {
    height: 220px;
  }
  .cardBody {
    padding: 0.8rem 1rem 0.8rem 1rem;
  }
  .cardHeader {
    font-size: 20px;
  }
  .cardBody p {
    font-size: 13px;
  }
  .cardBody .btn {
    font-size: 12px;
  }
}

@media (max-width: 785px) {
  .card {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .card .cardImage {
    height: 240px;
  }
  .cardHeader {
    font-size: 20px;
  }
  .cardBody {
    padding: 1rem 1.2rem 1rem 1.2rem;
  }
  .cardBody p {
    font-size: 13px;
    height: 110px;
  }
  .cardBody .btn {
    padding: 0 16px;
    font-size: 12px;
  }
}

@media (max-width: 399px) {
  .card {
    width: 250px;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .card .cardImage {
    height: 200px;
  }
  .cardHeader {
    font-size: 18px;
  }
  .cardBody p {
    font-size: 12px;
    height: 125px;
  }
  .cardBody {
    padding: 0.8rem 1rem 0.8rem 1rem;
  }
}
@media (max-width:870px) and (min-width:785px){
  .cardBody p{
    height: 135px;
  }
}