.Home {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.newsletter {
  background-color: #f5f5f5;
}
