/* .mapContainer {
  background: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEywbKdhsaQUyLuWP3hEWXPA3BwGHt3p3KaA&usqp=CAU") center
  center/cover no-repeat;
} */
.mapdiv {
  margin: 0 auto;
  transition: 0.2s;
}
/* 
.iframediv {
  padding: 3rem 0 0;
} */
.iframediv {
  height: 450px;
  box-shadow: inset 0 -10px 20px -10px rgba(0, 0, 0, 0.45);
}
.mapdiv iframe {
  /* border-radius: 0.625rem; */
  height: 450px;
  transition: 0.2s;
}

@media (max-width: 991px) {
  .mapdiv iframe {
    height: 400px;
  }
  .iframediv {
    height: 400px;
  }
}

@media (max-width: 399px) {
  .mapdiv iframe {
    height: 300px;
  }
  .iframediv {
    padding-bottom: 0;
    height: 300px;
  }
}
