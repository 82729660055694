@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Montserrat:wght@700&family=Open+Sans&family=PT+Serif&family=Poppins&family=Roboto&family=Squada+One&display=swap');

.successSection{
  width:100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow-x: hidden;
  background:url("../../assets/background/bgDarkH.avif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  font-family: "Google Sans", Arial, Helvetica, sans-serif;
}
.successSection::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/background/bgDarkH.avif");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* filter: blur(2px) brightness(1) contrast(1) saturate(0.9); */
  /* z-index: 1;  */
}
.successContainer {
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  width:100vw;
  padding: 40px;
  text-align: center;
  font-family: "poppins";
  z-index: 2;
  overflow: hidden;
  
}

.successHeader {
  font-size: 2rem;
  margin-bottom: 2rem;
  
}

.successSection .successFooter{
  margin: 10vw 2px;
  font-size: 0.9rem;
  color: white;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 2;

}
.successSection .footerText{
  color: #ffffff;
  font-size: 20px;
}
.successMessage {
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #bcd4b9;
}

.successButton {
  background-color: #50dca6;
  border: none;
  color: white;
  padding: 12px 28px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
  z-index: 2;
}


.successButton:hover {
  background-color: #104733;
}

@media screen and (max-width: 480px) and (min-width: 356px){
  .successMessage{
   font-size: 14px;
  }
  
  .successContainer .successFooter{
   padding:20px 0;
  }
 }
 
 @media screen and (max-width: 356px) {
  .successContainer .successHeader{
   font-size: 1.7rem;
   margin-bottom: 2rem;
   margin-top: 2.5rem;
  }
  .successContainer .footerText{
   font-size: 16px;
   margin-bottom: 4rem;
  }
 
 
  .successButton{
   padding: 9px 24px;
  }
 
  }