.signinPage{
  align-items: center;
   display: flex;
   flex-direction: column;
   font-family: Google Sans,Arial,Helvetica,sans-serif;
   height: 100%;
   min-height: 100vh;
   justify-content: center;
   overflow: hidden;
   /* padding: 2rem 0; */

}

Footer{
  position: absolute;
  z-index: 2;
}
.signinPage::before{
   content: "";
    height: 145vh;
 position: absolute;
 width: 100%;
   background-image: url("../../assets/background/traf-ukTd6UiQbLQ-unsplash.jpg");
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   /* background-attachment: fixed; */
   filter: blur(1px) brightness(0.3) contrast(1) saturate(0.7);
   z-index: 1;
}

.signinPage img{

 width:15vw;
 height: 80vh;
  position: relative;
  left:30vw;
}
.signinPage .heading{
  width: 100vw;
margin: 10px;
margin-top: 25px;
text-align: center;
 color: #50dca6;
 font-weight: 700;
 z-index: 2;
}
.subheading{
 margin: 0;
 color: rgb(83 139 126);
 font-weight: 400;
 z-index: 2;
}

.prologueHeading{
      /* margin: 10px; */
      color: #bde2d4;
      margin: 10px;
      margin-top: 37px;
      font-weight: 700;
      display: flex;
      text-align: center;
      justify-content: center;
      z-index: 2;
      position: relative;
}

.prologueSubheading{
  margin: 18px;
  color: #9ba8a3;
  /* font-weight: 700; */
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 2;
  position: relative;
  font-size: 18px;
}

.timeline{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.timeline-container {
 position: relative;
 max-width: 800px;
 margin: 0 auto;
 padding: 50px 0;
 z-index: 2;
}

.timeline-item {
 position: relative;
 margin: 50px 0;
 padding: 20px;
 border-radius: 5px;
 box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
 background-color: #fff;
 cursor: pointer;
 transition: all 0.3s ease-in-out;
}

.timeline-item:before {
 content: "";
 position: absolute;
 top: 0;
 left: 50%;
 margin-left: -2px;
 width: 4px;
 height: 100%;
 background-color: #f00;
 z-index: -1;
 transition: all 0.3s ease-in-out;
}

.timeline-item:after {
 content: "";
 position: absolute;
 top: 20px;
 left:-10px;
 width: 20px;
 height: 20px;
 border-radius: 50%;
 background-color: #ffffff;
 box-shadow: 0 0 0 3px #f00;
 z-index: 1;
 transition: all 0.3s ease-in-out;
 }
 
 .timeline-item-content {
 margin-left: 60px;
 }
 
 .timeline-item-date {
 display: block;
 font-size: 1.2rem;
 font-weight: bold;
 margin-bottom: 5px;
 }
 
 .timeline-item-title {
 font-size: 1.5rem;
 font-weight: bold;
 margin: 10px 0;
 }
 
 .timeline-item-description {
 font-size: 1.2rem;
 line-height: 1.5;
 color: #666;
 }
 
 .timeline-item-separator {
 position: absolute;
 top: 0;
 left: 50%;
 margin-left: -1px;
 width: 2px;
 height: 50px;
 background-color: #f00;
 z-index: -1;
 transition: all 0.3s ease-in-out;
 }
 
 .timeline-item-show {
 transform: translateY(-50px);
 }

 :before {
 height: 50px;
 }
 

.timeline_animation .timeline_item_1 {
 animation: slideInLeft 0.5s ease-in-out;
}

.timeline_animation .timeline_item_2 {
 animation: slideInRight 0.5s ease-in-out;
}

.timeline_animation .timeline_item_3 {
 animation: slideInLeft 0.5s ease-in-out;
}

.timeline_animation .timeline_item_4 {
 animation: slideInRight 0.5s ease-in-out;
}

@keyframes slideInLeft {
 from {
   opacity: 0;
   transform: translateX(-50%);
 }
 to {
   opacity: 1;
   transform: translateX(0);
 }
}

@keyframes slideInRight {
 from {
   opacity: 0;
   transform: translateX(50%);
 }
 to {
   opacity: 1;
   transform: translateX(0);
 }
}


.css-7kp53o-MuiTimelineDot-root {
 display: -webkit-box;
 display: -webkit-flex;
 display: -ms-flexbox;
 display: flex;
 -webkit-align-self: baseline;
 -ms-flex-item-align: baseline;
 align-self: baseline;
 border-style: solid;
 border-width: 2px;
 padding: 4px;
 border-radius: 50%;
 box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
 margin: 11.5px 0;
 border-color: transparent;
 color: #fff;
 background-color: #27abb0;
}



.signinbtn {
 background-color: #45bc8e;
 border: none;
 color: white;
 padding: 12px 28px;
 text-align: center;
 text-decoration: none;
 display: inline-block;
 font-size: 16px;
 margin: 4px 2px;
 transition-duration: 0.4s;
 cursor: pointer;
 box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
 z-index: 2;
 position: relative;
 margin-bottom: 43px;
}

.signinbtn:hover {
 box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
 background-color: #296d63;
}

.css-1nb3yh0-MuiTimelineConnector-root {
 width: 2px;
 background-color: #bdbdbd;
 -webkit-box-flex: 1;
 -webkit-flex-grow: 1;
 -ms-flex-positive: 1;
 flex-grow: 1;
 background-color: #0a0a0a;
}

.signinPage .buttonBox{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100vw;
   text-align: center;
}

.css-ahj2mt-MuiTypography-root {
   margin: 0;
   font-family: "Roboto","Helvetica","Arial",sans-serif;
   font-weight: 400;
   font-size: 0.7rem;
   line-height: 1.5;
   letter-spacing: 0.00938em;
}

@media screen and (max-width: 409px) {
 .heading{
   margin-top: 35px;
 }

}